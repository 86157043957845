import React from "react";
import ServiceLayout from "../../components/ServiceLayout";
import ServiceTitle from "../../components/ServiceTitle";
import Img from "react-cloudinary-lazy-image";
import { graphql } from "gatsby";
import ServiceCard from "../../components/ServiceCard";
import "../../styles.css";

export default props => {
  const markdownNodes = props.data.allMarkdownRemark.nodes;
  const logoImages = props.data.allCloudinaryMedia.nodes;

  const titleComponent = (
    <ServiceTitle
      title='Stellar Logo Design'
      subtitle='Instantly recognizable, we believe your logo is the reflection of your business at a glance. Make it a memorable one. Take a look below to see some of our recent work!'
    />
  );

  const content = markdownNodes.map((node, index) => {
    const { title, description, public_id, url } = node.frontmatter;
    const matchingLogoImageNode = logoImages.find(node => node.public_id === public_id);

    const imgComponent = (
      <Img
        imageName={matchingLogoImageNode.public_id}
        cloudName='frannsoft'
        fluid={{ maxWidth: 1300, maxHeight: 400 }}
        style={{
          borderRadius: `0.25rem 0.25rem 0 0`,
        }}
      />
    );

    return (
      <ServiceCard
        key={index}
        imgComponent={imgComponent}
        url={url}
        title={title}
        description={description}
      />
    );
  });

  return <ServiceLayout titleComponent={titleComponent}
    helmetTitle="Professional Logo Designer, Cleveland, Westlake, OH | Red Brick Road Studio"
  >{content}</ServiceLayout>;
};

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { tags: { in: "logos" } } }) {
      nodes {
        frontmatter {
          title
          description
          public_id
          url
        }
      }
    }
    allCloudinaryMedia(filter: { url: { regex: "*/(logos)/" } }) {
      nodes {
        public_id
        url
      }
    }
  }
`;
