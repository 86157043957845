import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
export default props => {
  const { imgSrc, title, url, description, imgComponent } = props

  return (
    <div className='rounded shadow-md pb-6 sm:mx-auto my-8 max-w-2xl'>
      {imgComponent ? (
        imgComponent
      ) : (
        <img className='rounded-t object-cover w-full' src={imgSrc} alt={title} />
      )}
      <div className='px-6 lg:px-10 pt-6 pb-4 lg:pt-8 lg:pb-6 text-gray-800'>
        <div className='pb-2 mb-2 md:mb-4 flex items-center justify-between'>
          <a
            className='font-semibold text-primary hover:text-primary text-xl md:text-2xl lg:text-3xl'
            href={url}
            rel='noreferrer noopener'
            target='_blank'>
            {title}
          </a>
          <a className='text-primary' href={url} target='_blank' rel='noreferrer noopener'>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        </div>
        <p className='text-sm lg:text-lg'>{description}</p>
      </div>
    </div>
  )
}
